// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    display: flex;
    flex-direction: column;
    background-color: #F2F5F8;
    height: 100vh;
    overflow: hidden;
}


.container-body{
    display: flex;
    height: 100vh;
}

.container-main{
    overflow: auto;
    min-height: 100vh;
}

.container-padding{
    padding: 0 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #F2F5F8;\r\n    height: 100vh;\r\n    overflow: hidden;\r\n}\r\n\r\n\r\n.container-body{\r\n    display: flex;\r\n    height: 100vh;\r\n}\r\n\r\n.container-main{\r\n    overflow: auto;\r\n    min-height: 100vh;\r\n}\r\n\r\n.container-padding{\r\n    padding: 0 2rem;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
